<template>
  <div class="ui one column relaxed grid" id="status-page">
    <div id="main-view" class="column"></div>
    <img id="sfNeonLogo" class="centered column" alt="Starface Logo"
         src="https://starface.com/app/uploads/2021/08/starface-logo-horizontal-1.svg">

    <div class="ui inverted container segment">
      <status-list/>
    </div>
  </div>
</template>

<script lang="ts">
import statusList from "./components/statusServiceList.vue"

export default {
  name: 'App',
  components: {
    statusList
  }, methods: {},

}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

#main-view {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #171e42;

}

#sfNeonLogo {
  top: 0;
  height: 100%;
  width: 50%;

  background-color: #171e42;

}

#status-view {
  background-color: #050b2b;
}
</style>

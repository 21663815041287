
import statusList from "./components/statusServiceList.vue"

export default {
  name: 'App',
  components: {
    statusList
  }, methods: {},

}

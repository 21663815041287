
import {onMounted, ref} from "vue";

export default {
  name: 'statusService',
  props: {},
  methods: {},
  setup() {

    const serviceInfo = ref([]);

    onMounted(async () => {
      setInterval(async () => {
        try {
          const response = await fetch("/api/info/")
          serviceInfo.value = await response.json()
        } catch (e) {
          console.log(e)
        }
      }, 1000)
    })

    return { serviceInfo }
  }
}

<template>
    <table id="service-list" class="ui inverted celled padded table">
      <thead>
      <tr class="single line">
        <th scope="row">Service name</th>
        <th>Service Version</th>
        <th>Build time</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(info,i) in serviceInfo" :key="i">
        <th scope="row" :name="info.name">{{ info.name }}</th>
        <td>{{ info.version }}</td>
        <td>{{ info.time }}</td>
      </tr>
      </tbody>
    </table>
</template>

<script lang="ts">
import {onMounted, ref} from "vue";

export default {
  name: 'statusService',
  props: {},
  methods: {},
  setup() {

    const serviceInfo = ref([]);

    onMounted(async () => {
      setInterval(async () => {
        try {
          const response = await fetch("/api/info/")
          serviceInfo.value = await response.json()
        } catch (e) {
          console.log(e)
        }
      }, 1000)
    })

    return { serviceInfo }
  }
}
</script>


